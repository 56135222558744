import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.url.js";
import "core-js/modules/web.url-search-params.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.filter.js";
import axiosOrg from 'axios';
export default {
  name: "Organization",
  components: {},
  data: function data() {
    return {
      // 筛选
      filter: [{
        placeholder: '机构状态',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '正常'
        }, {
          id: '-1',
          name: '禁用'
        }]
      }, {
        placeholder: '是否内部机构',
        value: '',
        type: 'select',
        list: [{
          id: '1',
          name: '内部机构'
        }, {
          id: '0',
          name: '外部机构'
        }]
      }, {
        placeholder: '机构名称',
        value: '',
        type: 'text',
        list: []
      }, {
        placeholder: '机构号',
        value: '',
        type: 'text',
        list: []
      }],
      postListData: {
        name: '',
        orgNo: '',
        interior: '',
        state: '',
        pageSize: '',
        pageIndex: ''
      },
      //用户存储的信息
      userInfo: this.Ls.getParseToken('nof_userInfo'),
      //List接口返回表格数据
      tableData: [],
      total: 0,
      //页数总数
      currentPage: 1,
      // 当前页数
      pageSize: 20,
      //每页个数
      //抽屉数据
      drawerState: '',
      drawerData: {
        name: '',
        address: '',
        type: '',
        nature: '',
        region: '',
        agent: '',
        remark: '',
        interior: 0,
        //是否内部用户：0不是 1是
        state: 1,
        //客服状态：1启用-1禁用
        province: '',
        city: '',
        district: '',
        managerId: '',
        instructorId: ''
      },
      drawer: false,
      direction: 'rtl',
      typeOptions: [{
        id: 1,
        name: '幼儿园'
      }, {
        id: 2,
        name: '培训机构'
      }, {
        id: 3,
        name: '个人'
      }, {
        id: 4,
        name: '其他'
      }],
      // 添加的时候有"体验园"的选项，修改编辑的时候不显示"体验园"
      natureOptions: [{
        id: 1,
        name: '民办'
      }, {
        id: 2,
        name: '公办'
      }, {
        id: 3,
        name: '企办'
      }, {
        id: 5,
        name: '体验园'
      }, {
        id: 4,
        name: '其他'
      }],
      natureState: false,
      //编辑时候当前园所是否为体验园
      provinceList: [],
      cityList: [],
      districtList: [],
      //搜索关键词
      input: '',
      // 删除弹框显示隐藏
      delId: '',
      centerDialogVisible: false,
      managerList: [],
      instructorList: [],
      outerVisible: false,
      instructorVisible: false,
      managerRadio: '',
      instructorRadio: '',
      managerDialog: {
        orgName: '',
        manager: '',
        managerId: ''
      },
      instructorDialog: {
        orgName: '',
        instructor: '',
        instructorId: ''
      }
    };
  },
  watch: {},
  mounted: function mounted() {
    this.initData();
    this.getAllProvince();
    this.getAllManager();
    this.getAllInstructor();
  },
  methods: {
    //文件改变
    changeFile: function changeFile() {
      console.log("调用了changeFile");
    },
    //导入弹框-上传接口
    httpRequest: function httpRequest(item) {
      var _this = this;
      console.log("调用了httpRequest");
      console.log(item.file); //拿到上传的文件
      var formData = new FormData();
      this.file = item.file;
      if (this.file) {
        this.importFileState = true;
      } else {
        this.importFileState = false;
      }
      formData.append('file', this.file);
      formData.append('userId', this.userInfo.userId);
      formData.append('roleId', this.userInfo.roleId);
      this.$http({
        url: 'http://192.168.0.200:9031/api/admin/organization/importOrgToken',
        //后端提供的接口
        method: 'post',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(function (_ref) {
        var data = _ref.data;
        if (data.code === 1) {
          _this.$message.success(data.msg);
          _this.getData();
          _this.cancel();
        } else {
          _this.$message.error(data.msg);
        }
      });
    },
    //下载excel
    // 导出
    exportExcel: function exportExcel(url, params) {
      var params = params || {};
      axiosOrg({
        // method: 'GET',
        method: 'POST',
        url: url,
        data: params,
        responseType: 'blob'
      }).then(function (res) {
        console.log(res);
        var reg = /filename=(.*)(.*)/;
        var fileName = decodeURIComponent(res.headers["content-disposition"].match(reg)[1]);
        fileName = fileName.replace(/["']/g, '');

        // return;
        var stream = res.data; // 后端用stream返回Excel文件
        var blob = new Blob([stream]);
        if (res.status !== 200) {
          // 非正常业务码，执行错误处理
          // 注意：status字段名是团队自行定义的
        }

        // 前端获取业务码，成功执行正常业务
        var downloadElement = document.createElement('a');
        var href = window.URL.createObjectURL(blob); // 创建下载的链接
        downloadElement.href = href;
        downloadElement.download = fileName; // 下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); // 点击下载
        document.body.removeChild(downloadElement); // 下载完成移除元素
        window.URL.revokeObjectURL(href); // 释放掉blob对象
      }).catch(function (err) {
        console.log(err);
      });
    },
    // 添加按钮点击函数
    addFun: function addFun() {
      this.drawerState = 'add';
      this.cityList = [];
      this.districtList = [];
      this.natureState = false;
      this.natureOptions = [{
        id: 1,
        name: '民办'
      }, {
        id: 2,
        name: '公办'
      }, {
        id: 3,
        name: '企办'
      }, {
        id: 5,
        name: '体验园'
      }, {
        id: 4,
        name: '其他'
      }];
      this.drawer = true;
      this.drawerData = {
        name: '',
        address: '',
        type: '',
        nature: '',
        region: '',
        agent: '',
        remark: '',
        interior: 0,
        //是否内部用户：0不是 1是
        state: 1,
        //客服状态：1启用-1禁用
        managerId: '',
        //客户经理
        instructorId: '' //教研员        
      };
    },

    // 导入机构授权列表
    importOrgAuth: function importOrgAuth() {},
    // 导出客户授权列表
    exportOrgAuth: function exportOrgAuth() {
      var proEnv = require('../../../config/pro.env'); // 生产环境
      console.log(proEnv);
      var hostUrl = proEnv.hosturl;
      var url = hostUrl + "/admin/organization/exportOrgToken";
      // let url="https://api.onefun.com.cn/api/admin/organization/exportOrgToken";
      this.exportExcel(url);
      // exportOrgToken
      // this.service.axiosUtils({
      //   requestName: 'exportOrgToken',
      //   data: {},
      //   sucFn: res => {
      //     console.log(res)
      //    window.open(res)
      //
      //   }
      // })
    },

    // 导出园所班级信息
    exportOrgClass: function exportOrgClass() {
      var proEnv = require('../../../config/pro.env'); // 生产环境
      console.log(proEnv);
      var hostUrl = proEnv.hosturl;
      var url = hostUrl + "/admin/organization/exportOrgClass";
      // let url="https://api.onefun.com.cn/api/admin/organization/exportOrgClass";
      this.exportExcel(url);
      // exportOrgToken
      // this.service.axiosUtils({
      //   requestName: 'exportOrgToken',
      //   data: {},
      //   sucFn: res => {
      //     console.log(res)
      //    window.open(res)
      //
      //   }
      // })
    },

    // 初始化列表
    initData: function initData() {
      var _this2 = this;
      this.postListData.pageSize = this.pageSize;
      this.postListData.pageIndex = this.currentPage;
      this.service.axiosUtils({
        requestName: 'getOrganizationList',
        data: this.postListData,
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            // this.$toast.success(res.message)
            _this2.tableData = res.data.data;
            _this2.total = res.data.total;
            _this2.tableData.map(function (item) {
              if (item.managerId === 0) {
                item.managerId = '';
              }
              if (item.instructorId === 0) {
                item.instructorId = '';
              }
              item.typeName = _this2.$common.getName(item.type, _this2.typeOptions);
              if (!item.typeName) {
                item.typeName = '暂无';
              }
              item.natureName = _this2.$common.getName(item.nature, _this2.natureOptions);
              if (!item.natureName) {
                item.natureName = '暂无';
              }
              item.interior === 1 ? item.interiorName = '是' : item.interiorName = '否';
              if (item.agent) {
                item.agentName = item.agent;
              } else {
                item.agentName = '暂无';
              }
              if (item.region) {
                if (item.region['parents']['parents'].name && item.region['parents'].name && item.region['parents'].name) {
                  item.regionName = item.region['parents']['parents'].name + ' ' + item.region['parents'].name + ' ' + item.region.name;
                } else {
                  item.regionName = '当前数据可能不完整';
                }
              } else {
                item.regionName = '暂无';
              }
            });
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    // 页码修改pagesize
    handleSizeChange: function handleSizeChange(page) {
      this.pageSize = page;
      this.initData();
    },
    // 页码修改当前页码
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.initData();
    },
    getAllManager: function getAllManager() {
      var _this3 = this;
      this.service.axiosUtils({
        requestName: 'getManager',
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this3.managerList = res.data.list;
          } else {
            _this3.$toast.error(res.message);
          }
        }
      });
    },
    getAllInstructor: function getAllInstructor() {
      var _this4 = this;
      this.service.axiosUtils({
        requestName: 'getInstructor',
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this4.instructorList = res.data.list;
          } else {
            _this4.$toast.error(res.message);
          }
        }
      });
    },
    getAllProvince: function getAllProvince() {
      var _this5 = this;
      //getProvince
      this.service.axiosUtils({
        requestName: 'getProvince',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this5.provinceList = res.data;
          } else {
            _this5.$toast.error(res.message);
          }
        }
      });
    },
    getCityFun: function getCityFun(val) {
      var _this6 = this;
      this.drawerData.city = '';
      this.drawerData.district = '';
      this.drawerData.region = '';
      this.service.axiosUtils({
        requestName: 'getCity',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          provinceId: val
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this6.cityList = res.data;
          } else {}
        }
      });
    },
    getDistrictFun: function getDistrictFun(id) {
      var _this7 = this;
      this.drawerData.district = '';
      this.drawerData.region = '';
      this.service.axiosUtils({
        requestName: 'getDistrict',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          cityId: id
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this7.districtList = res.data;
          } else {}
        }
      });
    },
    setRegion: function setRegion(val) {
      this.drawerData.region = val;
    },
    // 修改正常/禁用状态
    editState: function editState(row) {
      var _this8 = this;
      var changedState;
      if (row.state === 1) changedState = -1;else changedState = 1;
      this.service.axiosUtils({
        requestName: 'changeOrganizationState',
        data: {
          userId: this.userInfo.userId,
          roleId: this.userInfo.roleId,
          id: row.id,
          state: changedState
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this8.$toast.success(res.message);
            if (row.state === 1) row.state = -1;else row.state = 1;
          } else {
            _this8.$toast.error(res.message);
          }
        }
      });
    },
    // 操作函数
    detail: function detail(row, type) {
      if (type === 'edit') {
        //编辑
        this.cityList = [];
        this.districtList = [];
        // 编辑：当前体验园，不能修改体验园-机构类型和机构性质不修改
        //         非体验园，随意切换非体验园所
        if (row.nature === 5) {
          // 体验园
          this.natureState = true;
          this.natureOptions = [{
            id: 1,
            name: '民办'
          }, {
            id: 2,
            name: '公办'
          }, {
            id: 3,
            name: '企办'
          }, {
            id: 5,
            name: '体验园'
          }, {
            id: 4,
            name: '其他'
          }];
        } else {
          this.natureOptions = [{
            id: 1,
            name: '民办'
          }, {
            id: 2,
            name: '公办'
          }, {
            id: 3,
            name: '企办'
          }, {
            id: 4,
            name: '其他'
          }];
        }
        this.drawerState = 'edit';
        this.drawer = true;
        this.drawerData.id = row.id;
        this.drawerData.name = row.name;
        this.drawerData.address = row.address;
        this.drawerData.type = row.type;
        this.drawerData.nature = row.nature;
        this.drawerData.agent = row.agent;
        this.drawerData.remark = row.remark;
        this.drawerData.interior = row.interior;
        this.drawerData.state = row.state;
        this.drawerData.managerId = row.managerId;
        this.drawerData.instructorId = row.instructorId;
        if (row.region && row.region['parents'] && row.region['parents']['parents']) {
          this.getCityFun(row.region['parents']['parents'].id);
          this.getDistrictFun(row.region['parents'].id);
          this.drawerData.region = row.region.id;
          this.drawerData.province = row.region['parents']['parents'].id;
          this.drawerData.city = row.region['parents'].id;
          this.drawerData.district = row.region.id;
        } else {
          this.drawerData.region = '';
          this.drawerData.province = '';
          this.drawerData.city = '';
          this.drawerData.district = '';
        }
      } else if (type === 'manage') {
        //跳转管理页面，将
        this.Ls.setToken('nof_orgId', row.id);
        this.Ls.setToken('nof_orgInfo', JSON.stringify({
          id: row.id,
          name: row.name,
          typeName: row.typeName,
          state: row.state,
          nature: row.nature,
          interior: row.interior
        }));
        this.$router.push({
          path: '/customer/manage',
          query: {
            id: row.id,
            nature: row.nature,
            name: row.name,
            interior: row.interior
          }
        });
      } else if (type === 'del') {
        this.centerDialogVisible = true;
        this.delId = row.id;
      } else if (type === 'fenpei') {
        // 分配客户经理
        this.outerVisible = true;
        this.managerRadio = row.managerId;
        this.managerDialog = {
          orgName: row.name,
          orgId: row.id,
          manager: row.manager,
          managerId: row.managerId
        };
      } else if (type === 'distribute') {
        // 分配教研员
        this.instructorVisible = true;
        this.instructorRadio = row.instructorId;
        this.instructorDialog = {
          orgName: row.name,
          orgId: row.id,
          instructor: row.instructor,
          instructorId: row.instructorId
        };
      } else {}
    },
    // 分配客户经理弹框确定函数
    dispenseConfirmFun: function dispenseConfirmFun() {
      var _this9 = this;
      if (this.managerRadio) {
        this.service.axiosUtils({
          requestName: 'setToManager',
          data: {
            id: this.managerDialog.orgId,
            managerId: this.managerRadio
          },
          sucFn: function sucFn(res) {
            if (res.status === 1) {
              _this9.outerVisible = false;
              _this9.$toast.success(res.message);
              _this9.initData();
            } else {
              _this9.$toast.error(res.message);
            }
          }
        });
      } else {
        this.outerVisible = false;
      }
    },
    // 分配教研员弹框确定函数
    distributeConfirmFun: function distributeConfirmFun() {
      var _this10 = this;
      if (this.instructorRadio) {
        this.service.axiosUtils({
          requestName: 'setToInstructor',
          data: {
            id: this.instructorDialog.orgId,
            instructorId: this.instructorRadio
          },
          sucFn: function sucFn(res) {
            if (res.status === 1) {
              _this10.instructorVisible = false;
              _this10.$toast.success(res.message);
              _this10.initData();
            } else {
              _this10.$toast.error(res.message);
            }
          }
        });
      } else {
        this.instructorVisible = false;
      }
    },
    //删除弹框确定函数
    confirmFun: function confirmFun() {
      var _this11 = this;
      //删除当前的角色
      this.service.axiosUtils({
        requestName: 'deleteOrganization',
        data: {
          id: this.delId
        },
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            _this11.$toast.success(res.message);
            _this11.delId = '';
            _this11.centerDialogVisible = false;
            _this11.initData();
          } else {
            _this11.$toast.error(res.message);
          }
        }
      });
    },
    // 抽屉确定函数
    confirmClick: function confirmClick(formName) {
      var _this12 = this;
      var that = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var url = '';
          if (that.drawerState === 'edit') {
            url = 'editOrganization';
          } else {
            url = 'addOrganization';
          }
          that.service.axiosUtils({
            requestName: url,
            data: that.drawerData,
            sucFn: function sucFn(res) {
              if (res.status === 1) {
                that.$toast.success(res.message);
                that.drawer = false;
                _this12.natureState = false;
                that.$nextTick(function () {
                  that.$refs['form'].resetFields();
                });
                that.initData();
              } else {
                that.$toast.error(res.message);
              }
            }
          });
        }
      });
    },
    // 抽屉取消函数
    cancelClick: function cancelClick() {
      this.$nextTick(function () {
        this.$refs['form'].resetFields();
      });
      this.natureState = false;
      this.drawer = false;
    },
    searchFun: function searchFun() {
      this.initUserList(this.input);
    },
    searchClear: function searchClear() {
      this.input = '';
      this.initUserList();
    },
    // 筛选按钮函数
    search: function search(val) {
      var that = this;
      if (this.$common.checkSearchOrAllFilter(val)) {
        var filterData = val;
        this.postListData.name = filterData[2].value;
        this.postListData.orgNo = filterData[3].value;
        this.postListData.interior = filterData[1].value;
        this.postListData.state = filterData[0].value;
      } else {
        this.postListData.orgNo = '';
        this.postListData.interior = '';
        this.postListData.state = '';
        this.postListData.name = '';
      }
      that.initData();
    },
    reload: function reload() {
      for (var i = 0; i < this.filter.length; i++) {
        this.filter[i].value = '';
      }
      this.postListData.orgNo = '';
      this.postListData.interior = '';
      this.postListData.state = '';
      this.postListData.name = '';
      this.currentPage = 1;
      this.initData();
    },
    selectTypeChanged: function selectTypeChanged(val) {
      if (this.drawerData.nature === 5 && val !== 1) {
        this.drawerData.nature = '';
      }
    },
    selectNatureChanged: function selectNatureChanged(val) {
      if (val === 5) {
        this.drawerData.type = 1;
      }
    }
  }
};